<template>
  <div class="logistics_box">
    <div class="title">
        <img src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>物流消息</span>
    </div>
    <div class="site site2">
        <div class="site_text">
            <div class="left">
            <img class="imgdinwei" src="../../../../src/assets/image/dinwei.png" alt="">
            <div class="text1">{{address.name}}  {{address.phone}}</div>
            </div>
        </div>
        <div class="time">{{address.area}} {{address.details}}</div>
    </div>
    <div class="logistics_list">
        <van-steps direction="vertical" :active="0" active-color="#333" inactive-color="#939297">
            <van-step v-for="(item, index) in list" :key="index">
                <h3>{{item.context}}</h3>
                <p>{{item.time}}</p>
            </van-step>
        </van-steps>
    </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { expressInfo, getMrAddress } from '@/api/order'
export default {
  name: "logistics",

  data() {
    return {
      list: [],
      address: {}
    };
  },

  mounted() {
    this.getInfo()
    this.getAddress()
  },

  methods: {
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    getInfo() {
      expressInfo(this.$route.params.orderId).then(res => {
        this.list = res.data.progress
      })
    },
    // 获取默认地址
    getAddress() {
        getMrAddress().then(res => {
            this.address = res.data
        })
    },
  },
};
</script>

<style lang="less" scoped>
.logistics_box{
   background: #F6F7FA;
}
.title{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
.site{
    width: calc(100vw - 30rem);
    // height: 44rem;
    background: #FFFFFF;
    padding: 15rem;
    .site_text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          display: flex;
          .imgdinwei{
            width: 20rem;
          }
          .text1{
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 15rem;
            margin-left: 11rem;
            color: #333333;
          }
        }
        .imgright{
            width: 7rem;
        }
    }
    .time{
      font-family: PingFangSC, PingFang SC;
      font-weight: 100;
      font-size: 14rem;
      color: #000000;
      padding-left: 33rem;
      margin-top: 4rem;
    }
}
.site2{
    border-top: 1rem solid #F6F7FA;
  .imgdinwei{
    width: 23rem !important;
  }
}
.logistics_list{
    margin-top: 10rem;
    height: calc(100vh - 154rem);
    background: #fff;
    padding: 0rem 16rem;
}
</style>
